.c-sideBtn {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border-radius: 15px 0px 0px 15px;
  z-index: 5;

  @include mq-max(sm) {
    height: 70px;
    border-radius: 0px;
    top: auto;
    bottom: 0;
    right: 0;
    transform: none;
    width: 100%;
  }

  &__item {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-image: url('/inc/image/common/bg_sideBtn.png');
    background-size: cover;
    transition: all 0.2s ease;
    border-bottom: 1px solid $white;
    border-radius: 10px 0 0 10px;

    &:last-child {
      border-bottom: none;
    }

    @include mq-max(sm) {
      border-bottom: none;
      border-right: 1px solid $white;
      bottom: 0px;
      border-radius: initial;
      background-image: url('/inc/image/common/bg_sideBtn-sp.jpg');
      padding: 10px 10px 0;

      &:last-child {
        border-right: none;
      }
    }
    @include mq-max(sm) {
      position: relative;
      right: 0;
      // width: 50%;
    }
  }

  &__link {
    width: 90px;
    height: 228px;
    border-radius: 6px 0 0 6px;
    color: $black;
    display: block;
    text-align: left;
    position: relative;

    @include mq-max(sm) {
      background-color: $white;
      width: 100%;
      height: 70px;
      border-radius: 6px;
    }
    &:after {
      content: none !important;
    }

    span {
      position: relative;

      @include mq-max(sm) {
        display: inline-block;
        height: 100%;
        text-align: center;
        line-height: 70px;
        font-weight: bold;
      }
      //&::after {
      //  @include fa()
      //}

      //&::before {
      //  content: '';
      //  color: $accentColor;
      //  font-family: $FontAwesome;
      //  font-weight: 600;
      //  font-size: 24px;
      //  border-radius: 50%;
      //  //background-color: $white;
      //  width: 47px;
      //  height: 47px;
      //  //display: block;
      //  position: absolute;
      //  top: -50px;
      //  left: 50%;
      //  transform: translateX(-50%);

      //  @include mq-max(sm) {
      //    top: 50%;
      //    transform: translateY(-50%);
      //    left: -50px;
      //  }
      //}

    }

    &:hover {
      border-radius: 6px 0 0 6px !important;

      @include mq-max(sm) {
        border-radius: 0 !important;
      }
    }
  }
}