
.c-card02 {
  &__item {
    &>a {
      display: block;
      transition: all $transition;

      transition: all .2s;
      &:after {
        content: none;
      }
      &:hover {
        transform: translateY(-10px);
        transition: all .2s;
        @include mq-max(md) {
          transform: none;
        }
        .c-card02__detail {
          &:after {
            right: -20px;
          }
        }
      }
    }
  }
  &__inner {
    box-shadow: $box-shadow;
    border-radius: 10px;
  }
  &__thumb {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 240px;
    overflow: hidden;
    //padding-top: calc(233 / 360 * 100%);
    & img {
      height: 240px;
      object-fit: cover;
      object-position: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
  }
  &__body {
    padding: 13px 24px 45px;
    position: relative;
    background-color: $white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    @include mq-max(sm) {
      padding: 13px 22px 45px;
    }
  }
  &__title {
    font-size: 18px;
    font-weight: 500;
    color: $keyColor;
    margin: 5px 0;
  }
  &__desc {
    color: $textColor;
    margin: 0;
    line-height: 1.5;
  }
  &__detail {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: $keyColor;
    margin: 0;
    display: inline-block;
    position: absolute;
    bottom: 18px;
    right: 28px;
    &:after {
      @include fa('f138');
      position: absolute;
      top: 54%;
      right: -18px;
      transform: translateY(-50%);
      transition: all $transition;
    }
  }
}