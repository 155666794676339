//ページ内アンカーリンク

.c-anchor {
  margin-bottom: 60px;
  padding: 30px 15px 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @include mq-max(content) {
    padding: 30px 15px 25px;
  }
  @include mq-max(sm) {
    padding: 56px 15px 44px;
  }
  @include mq-max(xs) {
    margin-bottom: 30px;
  }
  &__item {
    display: inline-block;
    padding-right: 65px;
    margin-bottom: 10px;
    width: 100%;
    &>a {
      color: $textColor !important;
      border: 1px solid $keyColor;
      font-size: 16px;
      font-weight: 400;
      width: 100%;
      display: inline-block;
      position: relative;
      @include mq-max(xs) {
        font-size: 16px;
      }
      &:after {
        @include fa('f107');
        position: absolute;
        top: 54%;
        right: -0.8em;
        transform: translateY(-50%);
        line-height: 1;
        color: $keyColor;
      }
    }
  }
}