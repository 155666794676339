///////////////////////////////////////////////////////////////////////////////////////////////////////

//  text-align

///////////////////////////////////////////////////////////////////////////////////////////////////////

$breakpoint-value : xs,sm,md,lg;

.u-taL {
  text-align: left !important;
}
.u-taC {
  text-align: center !important;
  //「$breakpoint-value」で設定したブレイクポイント配列でクラスを作成。
  //例：.u-taC--xs-taL
  @each $breakpoint in $breakpoint-value {
    &--#{$breakpoint} {
      &-taL {
        @include mq-max(#{$breakpoint}) {
          text-align: left !important; //leftに切り替え
        }
      }
    }
  }
}
.u-taR {
  text-align: right !important;
}


///////////////////////////////////////////////////////////////////////////////////////////////////////

//  only系

///////////////////////////////////////////////////////////////////////////////////////////////////////

.u-only-xs {
  display: none !important;
  @include mq-max(xs) {
    display: block !important;
  }
}
.u-only-sm {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
  @include mq-max(xs) {
    display: none !important;
  }
}
.u-only-md {
  display: none !important;
  @include mq-max(md) {
    display: block !important;
  }
  @include mq-max(sm) {
    display: none !important;
  }
}
.u-only-lg {
  @include mq-max(md) {
    display: none !important;
  }
}

.u-under-sm {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
}

.u-not-under-sm {
  display: block !important;
  @include mq-max(sm) {
    display: none !important;
  }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////

//  単一クラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

.u-overflow {
  &-hidden {
    overflow: hidden !important;
  }
}