.l-togglenav {
  background-color: rgba(0,0,0,.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  margin: 0;
  top: 0;
  right: 0;
  display: none;
  overflow-y: scroll;
  overflow-x: hidden;
  &.is-open {
    display: block;
  }
  &__cvBtn {
    margin-bottom: 10px;
    .c-btn {
      min-width: 222px;
      padding: 15px 30px 15px 15px;
      @include mq-max(xs) {
        width: 100%;
        padding: 15px 30px 15px 15px;
        min-width: initial;
      }
    }
  }
  &__container {
    margin: 0;
    width: 35%;
    height: 100%;
    padding: 110px 30px 25px;
    float: right;
    background-color: $white;
    padding-top: $headerHeight;
    @include mq-max(md) {
      width: 50%;
      padding: 95px 30px 0;
    }
    @include mq-max(sm) {
      width: 100%;
    }
  }
  &__header {
    margin: 0;
    @include mq-max($changePoint) {
      margin-top: 40px;
    }
  }
  &__thumbnail {
    height: 100vh;
    padding-bottom: 0;
    padding-left: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @include mq-max(md) {
      display: none !important;
    }
  }
  &__logo {
    @include mq-max(xs) {
      display: none !important;
    }
    img {
      max-width: 145px;
    }
  }
  &__item {
    a {
      display: block;
      color: $textColor;
      padding: 10px 15px;
      @include mq-max(xs) {
        padding: 10px 0px;
      }
    }
    &.has-dropdown {
      position: relative;
      @include mq-max(sm) {
        &>a {
          position: relative;
          &::after {
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
            content: '';
            @include fa('f067');
          }
        }
      }
    }
  }
  &__main {
    margin-bottom: 15px;
    @include mq-max(md) {
      margin-bottom: 30px;
    }
    &>ul {
      padding-bottom: 0;
    }
  }
  &__footer {
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid $gray-l;
    font-size: 12px;
    color: $gray-d;
    text-align: center;
  }
  &__snsBox {
    width: 50%;
    margin-right: 15px;
    margin-left: auto;
    @include mq-max(lg) {
      width: 80%;
      margin-right: 0;
    }
  }
}

.l-dropDown--togglenav {
  @include mq-max(sm) {
    display: none;
  }
  &.is-open {
    @include mq-max(sm) {
      display: block;
    }
  }
  li {
    a {
      display: block;
      padding: 10px 15px 10px 40px;
    }
  }
}
