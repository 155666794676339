///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-ttl {
  //////////////////////////////////////////////////////////////////////////////////
  //メイン見出し（マクロ化済）
  //////////////////////////////////////////////////////////////////////////////////
  &-main {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: .05em;
    margin-bottom: 10px;
    @include mq-max(md) {
      font-size: 26px;
    }
    @include mq-max(sm) {
      font-size: 22px;
    }
    &>small {
      display: block;
      font-size: 18px;
      font-weight: 600;
      color: $keyColor;
      letter-spacing: 0.06em;
      margin-bottom: 5px;
    }
    &--line {
      padding-right: 70px;
      &>span {
        display: inline-block;
        position: relative;
        &:before,
        &:after {
          content: '';
          display: inline-block;
          height: 2px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        &:before {
          width: 54px;
          background-color: $gray;
          right: -1 * (54px + 9px);
        }
        &:after {
          width: 27px;
          right: -1 * (27px + 9px);
          background-color: $keyColor;
        }
      }
    }
  }



  //////////////////////////////////////////////////////////////////////////////////
  //中見出し
  //////////////////////////////////////////////////////////////////////////////////
  &-middle {}



  //////////////////////////////////////////////////////////////////////////////////
  //小見出し
  //////////////////////////////////////////////////////////////////////////////////
  &-small {}



  //////////////////////////////////////////////////////////////////////////////////
  //全体Modifier
  //////////////////////////////////////////////////////////////////////////////////
  //真ん中寄せ
  &--center {
    text-align: center;
  }

  //白文字
  &--white {
    color: $white;
  }

}