//iframeレスポンシブ調整用

// iframeをdiv.c-iframeで囲い、中にiframe.c-iframe__target
.c-iframe {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  &__target {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}