.l-header {
  position: absolute;
  z-index: 5;
  &__inner {
    width: calc(100vw - (#{$headerHeight}*2));
    height: $headerHeight;
    padding: 0 0 0 30px;
    display: flex;
    justify-content: space-between;
    @include mq-max(sm) {
      padding-left: 15px;
      width: auto;
    }
  }
  &__logo {
    max-width: 290px;
    width: 100%;
    display: flex;
    align-items: center;
    @include mq-max(sm) {
      max-width: 240px;
    }
    img {
      display: block;
      width: 100%;
    }
  }
  &__left {
    display: flex;
  }
  &__lang {
    .l-dropDown {
      width: 100px;
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

  //  ハンバーガーメニュー

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
.c-toggleBtn {
    width: 80px;
    height: 80px;
    z-index: 99999;
    pointer-events: auto;
    cursor: pointer;
    position: fixed;
    right: 150px;
    top: 30px;
    width: 42px;
    height: 42px;
    cursor: pointer;
    z-index: 111;
    @include mq-max(sm) {
      right: 30px;
    }
    span {
      display: block;
      position: absolute;
      width: 40px;
      border-bottom: solid 2px $keyColor;
      transition: .35s ease-in-out; //変化の速度を指定
      left: 6px;
      &:nth-child(1) {
        top: 9px;
      }
      &:nth-child(2) {
        top: 18px;
        width: 20px;
      }
      &:nth-child(3) {
        top: 27px;
      }
    }
    &.is-toggleActive {
      span{
        &:nth-child(1) {
          top: 18px;
          left: 6px;
          transform: rotate(-45deg);
        }
        &:nth-child(2),&:nth-child(3) {
          top: 18px;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          top: 10px;
          left: 8px;
        }
      }
    }
}