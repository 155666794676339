//テキスト系のユーティリティクラス

.u-txtBold {
  font-weight: 600;
}

.u-txtColor {
  &--keyColor {
    color: $keyColor;
  }
  &--red {
    color: $red;
  }
  &--white {
    color: $white;
  }
}

.u-txtBdr {
  padding: 0 5px;
  &--keyColor {
    background: linear-gradient(transparent 60%, $keyColor 60%);
  }
  &--yellow {
    background: linear-gradient(transparent 60%, #FFEF69 60%);
  }
}