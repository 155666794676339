//アコーディオン

$accBdrWidth: 1px;

///////////////////////////////////////////////////////////////////////////////////////

.c-accordion {
  &__item {
    // &:first-child {
    //   .c-accordion {}
    // }
    &:last-child {
      border-bottom: $accBdrWidth solid $bdrColor;
      // .c-accordion {}
    }
  }

  &__title {
    padding: 15px 50px 15px 15px;
    border-top: $accBdrWidth solid $bdrColor;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: all $transition;
    @include mq-max(sm) {
      padding-right: 40px;
    }
    &::after {
      @include fa('f067');
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      @include mq-max(sm) {
        right: 10px;
      }
    }
    &:hover {
      color: $keyColor;
    }

    //開いた状態
    &.is-accOpen {
      border-bottom: none;
      &::after {
        content: '\f068';
      }

      & + .c-accordion {
        &__body {
          padding-top: 0;
        }
      }
    }
  }

  &__body {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.75;
    padding: 15px 50px 15px 15px;
    transition: padding-top $transition;
    display: none;
    @include mq-max(sm) {
      padding-right: 15px;
    }
    p {
      line-height: inherit;
    }
  }



  //FAQ
  &--faq {
    .c-accordion {
      &__title {
        padding-left: 52px;
        &::before {
          content: '';
          width: 28px;
          height: 28px;
          display: inline-block;
          background: url('/inc/image/common/ico/ico_q.svg')no-repeat center / contain;
          position: absolute;
          top: 15px;
          left: 12px;
        }
        // &:hover {
        //   &::before {}
        // }
      }
      &__body {
        padding-left: 52px;
        @include mq-max(sm) {
          padding-left: 15px;
        }
      }
    }
  }
}