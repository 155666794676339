//スライダーの設定を読み込み(不要なら記述削除)
@import "cvSlider";

.c-cvArea {
  &__title {
    font-size: 28px;
    margin-bottom: 20px;
    color: $white;
    padding-right: 40px;
    @include mq-max(md) {
      text-align: center;
    }
    @include mq-max(sm) {
      padding-right: 0;
    }
    @include mq-max(xs) {
      font-size: 23px;
      margin-bottom: 30px;
    }
    &--white {
      color: $white;
    }
  }

  &__text {
    font-weight: 600;
    margin-bottom: 30px;

    //文字色白の場合
    &--white {
      color: $white;
    }
  }

  &__inner {
    padding: 300px 20px 100px;
    //背景画像を設定する場合webpを記載
    background-image: url('/inc/image/common/bg_cvArea.png');
    //background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    @include mq-max(md) {
      padding-top: 200px;
    }
    @include mq-max(sm) {
      padding-top: 100px;
      padding-bottom: 60px;
    }
    &__textArea {
      display: flex;
      justify-content: space-around;
      max-width: $contentWidth;
      margin: 0 auto;
      position: relative;
      z-index: 0;
      @include mq-max(md) {
        flex-direction: column;
      }
    }

    //背景画像にフィルターを付ける設定
    &--filter {
      &::after {
        content: '';
        opacity: .7;
        background-color: #051224;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }
    }
  }
  &__contact {
    display: flex;
    align-items: center;
    @include mq-max(md) {
      justify-content: center;
    }
    @include mq-max(sm) {
      flex-direction: column;
    }
  }
  &__mail {
    padding-left: 40px;
    @include mq-max(sm) {
      padding-top: 30px;
      padding-left: 0;
    }
    &>a {
      position: relative;
      display: block;
      background-color: $white;
      padding: 18px 25px 18px 55px;
      border-radius: $border-radius;
      font-weight: bold;
      &::before {
        position: absolute;
        @include fa(f0e0);
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 25px;
      }
    }
  }
  &__tel {
    padding: 0 60px;
    @include mq-max(xs) {
      padding: 0;
    }
  }
  &__time {
    color: $white;
    display: flex;
    justify-content: center;

    @include mq-max(lg) {
      text-align: center;
    }
    dt {
      margin-right: 1em;
    }

    dd {
      &:not(:last-child) {
        margin-right: 1em;
      }
    }
  }
  &__num {
    color: $white;
    display: block;
    font-size: 31px;
    font-weight: 600;
    position: relative;
    text-align: left;
    letter-spacing: 0.06em;
    padding-left: 40px;

    @include mq-max(lg) {
      text-align: center;
      padding-left: 40px;
      font-size: 26px;
    }

    &:before {
      @include fa('f095');
      position: absolute;
      top: 52%;
      left: 0;
      transform: translateY(-50%);
      transition: right $transition;
      font-size: 32px;

      @include mq-max(lg) {
        right: 250px;
      }
    }
  }

  &__fax {
    color: $white;
    display: block;
    font-size: 31px;
    font-weight: 600;
    position: relative;
    text-align: left;
    letter-spacing: 0.06em;
    padding-left: 40px;

    @include mq-max(lg) {
      text-align: center;
      padding-left: 40px;
      font-size: 26px;
    }

    &:before {
      @include fa('f1ac');
      position: absolute;
      top: 52%;
      left: 0;
      transform: translateY(-50%);
      transition: right $transition;
      font-size: 32px;

      @include mq-max(lg) {
        right: 250px;
      }
    }
  }
  
  &--gray {
    background-color: $gray-l;
  }

}