.l-navigation {
  height: 100%;
  padding-right: 10px;
  @include mq-max($changePoint) {
    display: none;
    height: auto;
  }
  &__top {
    display: flex;
    justify-content: flex-end;
    .l-navigation__item {
      &>a {
        color: $textColor;
        display: inline-block;
        padding: 25px 15px 0;
        transition: all $transition;
        &.current {
          border-bottom: 2px solid $keyColor;
        }
      }
    }
  }
  &__inner {
    //height: 100%;
    display: flex;
  }
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    &>a {
      color: $textColor;
      display: inline-block;
      padding: 10px 15px 10px;
      transition: all $transition;
      @include mq-max(lg) {
        padding: 10px;
      }
      &.current {
        border-bottom: 2px solid $keyColor;
      }
      @include mq-max(lg) {
        font-size: 14px;
      }
    }
    &:hover {
      &>a {
        color: $keyColor;
      }
      &.has-dropdown::after {
        color: $keyColor;
      }
    }
    //ドロップダウン
    &.has-dropdown {
      cursor: pointer;
      margin-right: 10px;
      color: $black;
      &::after {
        position: absolute;
        right: 0;
        content: '';
        @include fa('f107');
      }
    }
  }
}

.l-dropDown {
  opacity: 0;
  position: absolute;
  top: 55px;
  width: 250px;
  background-color: $white;
  z-index: 1;
  transition: all $transition;
  pointer-events: none;
  &.is-open {
    opacity: 1;
    transition: all $transition;
    pointer-events: auto;
  }
  &__item {
    padding: 10px 15px;
    a {
      width: 100%;
      display: block;
      color: $black;
      padding: 5px 0px;
    }
  }
}