//画像系

.c-picture {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }

  //Mod
  &--mbSmall {
    margin-bottom: 15px;
  }
  &--round {
    overflow: hidden;
    border-radius: $border-radius;
  }
}


//センターアイコン(縦横の長さはModで変更)
.c-centeredIco {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
  img {
    display: block;
    line-height: 1;
    width: 100%;
    height: 100%;
    object-fit: contain;
    font-family: "object-fit: contain;";
  }

  //Mod
  &--square80 {
    width: 80px;
    height: 80px;
  }

  &--center {
    margin-left: auto;
    margin-right: auto;
  }
}