.c-contactBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: $headerHeight;
  height: $headerHeight;
  &List {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99999;
    @include mq-max(sm) {
      display: none;
    }
  }
  &__link {
    display: block;
    line-height: 1;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding: 10px 10px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 28px auto;
  }
  &--mail {
    background-color: $keyColor;
    border-radius: 10px;
    .c-contactBtn__link {
      color: $white;
      &::before {
        display: block;
        @include fa(f0e0);
        font-weight: light;
        font-size: 35px;
        padding-bottom: 5px;
      }
    }
  }
  &--shop {
    background-color: $keyColor;
    .c-contactBtn__link {
      color: $white;
      background-image: url('/inc/image/common/ico/ico_nav-cart.svg');
    }
  }
}