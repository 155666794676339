//変数管理

$imageNumber: 4; //スライド画像の枚数

//pc版
$imageWidth: 480px; //スライド画像の横幅
$slidespeed: 30s; //スライドアニメーションの速度

//tablet版
$imageWidth-tablet: 275px; //スライド画像の横幅(タブレット)
$slidespeed-tablet: 30s; //スライドアニメーションの速度(スマホ)

//sp版
$imageWidth-sp: 275px; //スライド画像の横幅(スマホ)
$slidespeed-sp: 30s; //スライドアニメーションの速度(スマホ)



//スライダー設定
.c-imgSlider {
  padding: 0;
  margin: 0;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  animation: cvAreaScroll $slidespeed linear infinite;
  margin-left: -3px;
  @include mq-max(sm) {
    animation: cvAreaScroll-tablet $slidespeed linear infinite;
  }
  @include mq-max(sm) {
    animation: cvAreaScroll-sp $slidespeed-tablet linear infinite;
  }
  @include mq-max(xs) {
    animation: cvAreaScroll-sp $slidespeed-sp linear infinite;
  }

  &__wrapper {
    width: 100%;
    overflow: hidden;
    display: flex;
  }

  &__item {
    display: inline-block;
    list-style: none;
    text-align: center;
    line-height: 0;

    img {
      display: block;
      width: $imageWidth;
      height: auto;
      line-height: 0;
      @include mq-max(sm) {
        width: $imageWidth-tablet;
      }
      @include mq-max(xs) {
        width: $imageWidth-sp;
      }
    }
  }
}

//アニメーション設定
@keyframes cvAreaScroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-1 * #{$imageWidth} * #{$imageNumber}))
  }
}

@keyframes cvAreaScroll-tablet {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-1 * #{$imageWidth-tablet} * #{$imageNumber}))
  }
}

@keyframes cvAreaScroll-sp {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-1 * #{$imageWidth-sp} * #{$imageNumber}))
  }
}

.is-IE {
  & .c-imgSlider {
    display: none;
  }
}