///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ボタン

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-btn {
  position: relative;
  display: inline-block;
  font-size: 16px;
  padding: 20px 50px 20px 30px;
  letter-spacing: .05em;
  width: auto;
  color: $white;
  background-color: $keyColor;
  line-height: 1.25;
  text-align: center;
  font-weight: 500;
  margin-bottom: 30px;
  transition: color $transition,
              background-color $transition;
              border-radius: 9999px;
  &:last-child {
    margin-bottom: 0;
  }
  &::after {
    @include fa('f054');
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    transition: right $transition;
    line-height: 1;
  }
  &:hover {
    color: $white;
    background-color: $keyColor;
    &::after {
      right: 10px;
    }
  }


  //PDF
  &[href$=".pdf"] {
    &:hover {
      &::after {
        right: 16px;
        color: $white;
      }
    }
  }

  //外部リンク
  &[target="_blank"] {
    &::after {
      color: $keyColor;
    }
    &:hover {
      &::after {
        right: 16px;
        color: $white;
      }
    }
  }


  //Modifier
  //小さいボタン
  &--small {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  //反転
  &--reverse {
    color: $white;
    background-color: $keyColor;
    &:hover {
      color: $keyColor;
      background-color: $white;
    }
  }
  &__border {
    background-color: initial;
    border: initial;
    border-radius: initial;
    box-shadow: initial;
    border-bottom: 1px solid $gray;
    text-align: left;
    width: 100%;
    color: $black;
    padding: 35px 55px 16px 5px;
    &:hover {
      transform: translateY(0px);
      transition:  all 250m ease-in-out;
      opacity: .5;
      background-color: $white;
      color: $keyColor;
    }
    &:after {
      top: 65%;
    }
  }
}